<template>
  <div class="modal topup-modal">
    <div 
      class="overlay" 
      @click="$parent.closeTopup"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              src="./../img/close.svg"
              class="close" 
              @click="$parent.closeTopup" 
            >
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="desc">
                      <b>{{ $t('ADDING FUNDS TO BALANCE') }}</b>
                    </div>
                  </div>
                  <div class="topup-form">
                    <div class="desc">
                      {{ $t('PAYMENT METHOD:') }}
                    </div>
                    <div class="desc">
                      {{ $t('CREDIT CARD') }}
                    </div>

                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Name') }}
                      </div>
                      <input 
                        v-model="name"
                        type="text"
                        required
                        placeholder="Name"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Surname') }}
                      </div>
                      <input 
                        v-model="surname"
                        type="text"
                        required
                        placeholder="Surname"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Phone') }}
                      </div>
                      <input 
                        v-model="phone"
                        type="tel"
                        required
                        placeholder="Phone"
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Country') }}
                      </div>
                      <select required v-model="selectedCountry">
                        <option 
                          value="" 
                          disabled 
                          selected
                        >
                          {{ $t('Countries') }}
                        </option>
                        <option 
                          v-for="item in countries" 
                          :key="item.id"
                          :value="item" 
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('City') }}
                      </div>
                      <input
                        v-model="city"
                        type="text" 
                        placeholder="City"
                        required
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Address') }}
                      </div>
                      <input 
                        v-model="address"
                        type="text" 
                        placeholder="Address"
                        required
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Post Code') }}
                      </div>
                      <input 
                        v-model="postCode"
                        type="text" 
                        placeholder="Post Code"
                        required
                      >
                    </label>
                    <label class="input-container">
                      <div class="desc">
                        {{ $t('Enter amount') }}
                      </div>
                      <input 
                        v-model="amount"
                        type="text" 
                        placeholder="Enter amount"
                        @input="allowOnlyNumbers"
                        required
                      >
                      <div class="desc desc-curr">
                        {{ $parent.currency }}
                      </div>
                    </label>
                    <div class="cta-container">
                      <div class="chekbox-container">
                        <label class="chekbox-label">
                          <div class="chekbox-label-wrapper">
                            <input 
                              v-model="terms"
                              type="checkbox" 
                              name="terms"
                            >
                            <div class="checkbox" />
                            <span class="title">{{ $t('I agree with') }} </span>
                            <a 
                              class="title"
                              @click="$parent.goToPage('privacy')" 
                            > {{ $t('privacy policy') }}</a>
                            <span class="title"> {{ $t('and') }} </span>
                            <a 
                              class="title"
                              @click="$parent.goToPage('terms')" 
                            > {{ $t('terms and conditions') }}</a>
                          </div>
                        </label>
                      </div>
                    </div>
                    <!-- <div class="buttons">
                      <label>
                        <input v-model="amount" type="radio" value="5" />
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 5
                        </div>
                      </label>
                      <label>
                        <input v-model="amount" type="radio" value="10" />
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 10
                        </div>
                      </label>
                      <label>
                        <input v-model="amount" type="radio" value="30" />
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 30
                        </div>
                      </label>
                      <label>
                        <input v-model="amount" type="radio" value="50" />
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 50
                        </div>
                      </label>
                      <label>
                        <input v-model="amount" type="radio" value="100" />
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 100
                        </div>
                      </label>
                    </div> -->
                  </div>
                </div>
                <div class="cart-bottom">
                  <div class="desc">
                    You will get:
                    <b>{{ $parent.currency }} {{ amount }}</b>
                  </div>
                  <button 
                    :class="['button orange', {'disabled': !requiredRegisterFieldsAreFilled}]"
                    @click="send" 
                  >
                    {{ $t('Pay') }}
                  </button>
                </div>
                <transition name="slide">
                  <div 
                    v-if="$parent.error" 
                    class="error-desc desc red"
                  >
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopUp',
  props: [],
  data: function() {
    return {
      amount: 5,
      name: '',
      surname: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      countryOptions: [],
      countryOptionsId: [],
      paymentType: '',
      countries: [],
      terms: ''
    }
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  mounted() {
    this.getCounties();
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.phone = res.data.phone;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.countryId = res.data.country;
        this.city = res.data.city;
        this.address = res.data.address;
        this.postCode = res.data.zip;

        if (this.countries && this.countries.length && this.countryId) {
          this.setCountry();
        }
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignIn();
      }
    })
  },
  methods: {
    setCountry() {
      let mapped = this.countries.map(({
        id,
        title
      }) => ({
        id,
        title
      }));
      this.countries = mapped;
      this.selectedCountry = this.countries.find(item => item.id == this.countryId);

      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == this.selectedCountry.name) {
          this.countryId = this.countryOptionsId[i];
        }
      }

    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function(item) {
            return item['title'];
          });
          let newCountriesId = arr.map(function(item) {
            return item['id'];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
          this.countries = arr;
          let mapped = this.countries.map(({
            id,
            title
          }) => ({
            id,
            title
          }));
          this.countries = mapped;

          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    allowOnlyNumbers(){
      this.amount = this.amount.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    send() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "amount": this.amount,
        "country": this.selectedCountry.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode
      }
      this.$http.post(process.env.VUE_APP_API + 'deposit/checkout', data)
      .then((res) => {
        if (res.data.status == "OK") {
          window.location = res.data.redirect_url;
        } else {
          this.$emit('setError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setError', res.response.data.message)
      })
    }
  }
}
</script>