<template>
  <div class="slider-container">
    <slick ref="slick"  :options="slickOptions" class="slider products" @swipe="handleSwipe">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="item-wrapper">
          <img src="./../img/info.svg" class="info" @click="goToSliderItem(item)" />
          <div class="desc">{{item.category}}</div>
          <div class="price title small green">
            <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} {{$parent.currency}}</span>
            {{item.price}} <b>{{$parent.currency}}</b>
          </div>
          <a @click="goToSliderItem(item)" class="preview">
            <img :src="imgDomain + item.img_url" class="img"/>
            <div class="tag">{{tag}}</div>
            <transition name="fade">
              <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Added') }}</span>
            </transition>
          </a>
          <a @click="goToSliderItem(item)" class="desc small">{{item.title}}</a>
          <!-- <a @click="$parent.addToCart(item, item.id)" class="link">
            <span>{{ $t('Add to') }}</span>
            <svg id="shopping-cart_1_" data-name="shopping-cart (1)" xmlns="http://www.w3.org/2000/svg" width="19.367" height="18.119" viewBox="0 0 19.367 18.119">
              <path id="Контур_3" data-name="Контур 3" d="M50.367,94.873H35.607L34.745,91H31v1.291h2.71l2.462,11.125h-.008a3.109,3.109,0,0,0,1.607,2.712,1.942,1.942,0,1,0,3.439.408h4.111a1.919,1.919,0,0,0-.119.646,1.937,1.937,0,1,0,1.937-1.937H39.285a1.829,1.829,0,0,1-1.778-1.417l12.861-2.573Z" transform="translate(-31 -91)" fill="#8d8e92"/>
            </svg>
          </a> -->
        </div>
      </div>
    </slick>
  </div>
</template>

<script>

import Slick from 'vue-slick';
export default {
  name: 'SlickItem',
  props: ['list', 'tag'],
  components: {
    Slick
  },
  data: function() {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToSliderItem(item) {
      let self = this;
      setTimeout(()=> {
        if (self.sliderIsClickble == true) {
          console.log(3)
          self.$parent.goToProduct(item)
        }
      }, 200)
    },
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
  }
}
</script>