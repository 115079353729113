<template>
  <div class="modal skin-modal">
    <div class="overlay" @click="$emit('closeSaleSkinModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSaleSkinModal')" src="./../img/close.svg" />
      <div class="container">
        <form>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">{{ $t("Sell your skin") }}</div>
              <div class="form-fields-wrapper">
                <div class="input-container">
                  <div class="desc">{{ $t("Skin name") }}</div>
                  <input type="text" :placeholder="$t('Skin name')" />
                </div>
                <div class="input-container">
                  <div class="desc">{{ $t("Desired price") }}:</div>
                  <input type="text" :placeholder="$t('Desired price')" />
                </div>
                <div class="input-container">
                  <div class="desc">{{ $t("Quality") }}:</div>
                  <input type="text" :placeholder="$t('Quality')" />
                </div>
                <div class="input-container">
                  <div class="desc">{{ $t("Steam inventory link to skin") }}:</div>
                  <input type="text" :placeholder="$t('Steam inventory link to skin')" />
                </div>
                <button class="button" type="submit">
                  <span>{{ $t("Send") }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaleSkinModal",
  props: [],
  components: {},
  data: function () {
    return {};
  },
  methods: {
    closeSaleSkinModal() {
      this.$emit("closeSaleSkinModal");
    },
  },
};
</script>
