<template>
	<div class="modal order-modal">
    <div class="overlay" @click="$parent.closeOrderModal"></div>
    <div class="wrapper">
      <div class="container">
        <img src="./../img/close.svg" @click="$parent.closeOrderModal" class="close"/>
        <div class="title small">{{$t("Payment Method")}}</div>
        <div class="button-list-container">
          <div class="button-list" v-if="paymentMethods && paymentMethods.length">
            <button v-for="(item, i) in paymentMethods" class="img-btn" @click="submit(item.code)" :key="i">
              <img :src="$parent.appDomain + item.image" class="img"/>
              {{ item.title }}
            </button>
          </div>
          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{$parent.error}}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
	name: 'OrderModal',
	props: ['orderData'],
	data: function() {
		return {

		}
	},
	methods: {
		submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
	},
	mounted() {
  },
  computed: {
    paymentMethods () {
      if (this.$parent.paymentMethods && this.$parent.currency && this.$parent.currency in this.$parent.paymentMethods) {
        return this.$parent.paymentMethods[this.$parent.currency];
      }

      return this.$parent.paymentMethods;
    }
  }
}
</script>
