<template>
  <div class="modal cart-modal">
    <div class="overlay" @click="$parent.closeCart"></div>
    <div class="wrapper">
      <div class="container">
        <div class="title regular empty-title" v-if="!cartContents.length">
          {{ $t('Cart is empty!') }}
        </div>
        <div class="left" v-if="cartContents.length">
          <div class="title-container">
            <div class="title regular">
              <b>{{ $t('Your cart') }}</b>
            </div>
            <div class="desc">
              <b> {{cartContents.length}} {{ $t('Items') }}</b>
            </div>
          </div>
          <div class="product-list">
            <div class="item" v-for="(item, i) in cartContents" :key="i">
              <div class="product">
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img"/>
                </div>
                <div class="desc small">{{item.item.title}}</div>
              </div>
              <div class="item-right">
                <div class="price desc">
                  <b>
                    {{item.item.price}} <span class="currency">{{ currency }}</span>
                  </b>
                </div>
                <!-- <div class="desc">x {{item.count}}</div> -->
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../img/delete.svg" class="img"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right" v-if="cartContents.length">
          <div class="right-wrapper">
            <div class="confirm">
              <label class="input-container">
                <div class="desc">
                  {{ $t('Steam ID') }}
                </div>
                <input
                    v-model="steamId"
                    type="text"
                    required
                    placeholder="Steam ID"
                >
              </label>
            </div>
            <div class="total">
              <div class="desc small">
                <b>{{$t("Total")}}:</b>
              </div>
              <div class="desc">
                <b>
                  {{$parent.totalSum}} <span class="currency">{{ currency }}</span>
                </b>
              </div>
            </div>
            <div class="input-container">
              <div class="chekbox-container">
                <label class="chekbox-label">
                  <div class="chekbox-label-wrapper">
                    <input type="checkbox" name="terms" v-model="terms"/>
                    <div class="checkbox"></div>
                    <span class="title">{{$t("I agree with")}} </span>
                    <a @click="$parent.goToPage('privacy')" class="title">{{$t("Privacy Policy")}}</a>
                    <span class="title"> {{$t("and")}} </span>
                    <a @click="$parent.goToPage('terms')" class="title">{{$t("Terms and Conditions")}}</a>
                  </div>
                </label>
              </div>
            </div>
            <button @click="submit" :class="['button', {'disabled': !requiredFieldsAreFilled}]">
              <span>{{$t("Buy")}}</span>
            </button>
            <transition name="fade">
              <div class="desc red" v-if="$parent.error">{{$parent.error}}</div>
            </transition>
            <transition name="slide">
              <div 
                v-if="successMessage" 
                class="desc green"
              >
                {{ successMessage }}
              </div>
            </transition>
            <div class="no-funds" v-if="$parent.totalSum > balance">
              <div class="text">
                <div class="desc red">
                  <b>{{ $t('Not enough funds!') }}</b>
                </div>
                <div class="desc">
                  <div class="link" @click="$parent.openTopup">
                    {{ $t('Top up') }}
                  </div>
                  {{ $t('your balance!') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Cart',
  props: ['currency', 'cartContents'],
  components: {
  },
  data: function() {
    return {
      imgDomain: '',
      steamId: '',
      balance: '',
      terms: false,
      successMessage: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  watch: {
    currencyCode: function () {
      this.getProfile();
    }
  },
  methods: {
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        if (res.status == 200) {
          self.steamId = res.data.steamId;
          self.balance = res.data.balance;
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    submit() {
      let data = {
        "steamId": this.steamId
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/product', data)
      .then((res) => {
        let self = this;
        if (res.data.status == "OK") {
          this.$emit('setError', '');
          this.successMessage = 'Success';
          setTimeout(function() {
            self.getProfile();
            self.$parent.getDepositHistory();
            self.$parent.getOrderHistory();
            self.$parent.getCartContents();
            self.$router.push({path: '/profile/orders'});
          }, 1500)
          
        } else {
          this.$emit('setError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setError', res.response.data.message)
      })
    },
  }
}
</script>