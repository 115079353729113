<template>
  <div class="item-wrapper" v-if="item">
    <div class="text-top">
      <img src="./../img/info.svg" class="info" @click="goToSliderItem(item)" />
      <div class="desc">{{item.hero}}</div>
      <div class="price title small green">
        <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} {{$parent.currency}}</span>
        {{item.price}} <b>{{$parent.currency}}</b>
      </div>
      <a @click="goToSliderItem(item)" class="preview">
        <img :src="imgDomain + item.img_url" class="img"/>
        <transition name="fade">
          <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Added') }}</span>
        </transition>
      </a>
    </div>
    <a @click="goToSliderItem(item)" class="desc small">{{item.title}}</a>
  </div>
</template>

<script>

export default {
  name: "ProductCard",
  props: ["item", "sliderIsClickble", "currency", "addToCartChosenItem"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit("goToProduct", item);
        }
      }, 200);
    },
  },
};
</script>
