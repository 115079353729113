<template>
  <main class="main home-page">
    <div class="section hero-section">
      <div class="wrapper">
        <div class="left">
          <img src="./../img/hero.png" class="img"/>
        </div>
        <div class="right">
          <div class="title normal">
            <b>
              {{ $t('Welcome to Dota') }}
              <span>{{ $t('SKINS') }}</span>
            </b>
          </div>
          <div class="title">
            <b>{{ $t('Your Premier Dota 2 Skin Marketplace!') }}</b>
          </div>
          <div class="desc small">
            {{ $t('Unleash your true potential in the Dota 2 battlefield with our extensive collection of skins and cosmetics. At [Your Site Name], we offer a wide array of Dota 2 skins that will not only enhance your heroes\' appearances but also allow you to stand out in every match. ') }}
          </div>
          <router-link to="/products/all" class="button">{{ $t('Shop NOW') }}</router-link>
        </div>
      </div>
    </div>
    <div class="section popular-section">
      <div class="wrapper">
        <img src="./../img/smoke.png" class="cloud"/>
        <div class="title title-main">
          <b>{{ $t('Most popular') }}</b>
        </div>
        <div class="carousel-container product-list">
          <SlickItem v-if="popularProducts.length" :list="popularProducts" />
          <router-link to="/products/all" class="desc small link green2">
            <b>
              <span>{{ $t('See all') }}</span>
            </b>
          </router-link>
        </div>
      </div>
    </div>
    <div class="section why-section">
      <div class="wrapper">
        <div class="title title-main big">
          <b>{{ $t('Why Choose Us?') }}</b>
        </div>
        <div class="list">
          <div class="item">
            <img src="./../img/salve.png" class="img"/>
            <div class="title regular">
              <b>{{ $t('Vast Selection') }}</b>
            </div>
            <div class="desc small">
              {{ $t('Discover an expansive range of skins, from rare collectibles to affordable favorites, tailored to suit every hero and playstyle.') }}
            </div>
          </div>
          <div class="item">
            <img src="./../img/regen.png" class="img img2"/>
            <div class="title regular">
              <b>{{ $t('Competitive Pricing') }}</b>
            </div>
            <div class="desc small">
              {{ $t('Enjoy great deals and competitive prices on all items, making it easy to upgrade your inventory without overspending.') }}
            </div>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <img src="./../img/runes.png" class="img img3"/>
            <div class="title regular">
              <b>{{ $t('Secure and Reliable') }}</b>
            </div>
            <div class="desc small">
              {{ $t('We prioritize your security with top-notch encryption and trusted payment methods, ensuring that every transaction is safe and seamless.') }}
            </div>
          </div>
          <div class="item">
            <img src="./../img/magic.png" class="img img4"/>
            <div class="title regular">
              <b>{{ $t('Easy-to-Use Interface') }}</b>
            </div>
            <div class="desc small">
              {{ $t('Our user-friendly website design makes it easy to browse, select, and purchase skins, whether you’re a veteran player or new to Dota 2.') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section start-section">
      <div class="wrapper">
        <img src="./../img/balls.png" class="img"/>
        <div class="title">
          <b>{{ $t('Start Shopping Now!') }}</b>
        </div>
        <div class="desc small">
          {{ $t('Ready to enhance your Dota 2 experience? Browse our collection of skins and cosmetics today, and take your heroes to the next level. Your perfect skin is just a click away!') }}
        </div>
        <router-link to="/products/all" class="button long">{{ $t('Shop NOW') }}</router-link>
      </div>
    </div>
    <div class="section faq-section">
      <div class="wrapper">
        <div class="title">
          <b>{{ $t('FAQ') }}</b>
        </div>
        <div class="flex">
          <div class="left">
            <div class="list" v-if="faq && faq.length">
              <a v-for="(item, i) in faq" :key="i" :class="['title small', {'active': activeFaqItem === item}]" @click="activeFaqItem = item">
                <b>{{ item.question }}</b>
              </a>
            </div>
          </div>
          <div class="right" v-if="this.activeFaqItem">
            <div class="title normal">
              <b>{{ this.activeFaqItem.question }}</b>
            </div>
            <div class="desc small">
              {{ this.activeFaqItem.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';

export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem
  },
  data: function () {
    return {
      imgDomain: "",
      categoryOptions: [],
      qualitiyOptions: [],
      productList: [],
      popularProducts: [],
      faq: [],
      activeFaqItem: {}
    };
  },
  mounted() {
    this.getPopularProducts();
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCategoryOptions();
    this.getFaq();
  },
  computed: {
    finalProductsArray() {
      const finalArray = [];
      const includedQualities = new Set(); // To keep track of included qualities
      this.productList.forEach(product => {
        if (this.qualitiyOptions.includes(product.quality) && !includedQualities.has(product.quality)) {
          finalArray.push(product);
          includedQualities.add(product.quality);
        }
      });
      return finalArray;
    }
  },
  methods: {
    getFaq() {
      this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
        this.faq = res.data.payload;
        this.activeFaqItem = this.faq[0];
      })
    },
    getPopularProducts() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?new=true')
      .then((res) => {
        this.popularProducts = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getProducts() {
      this.$http
      .get(process.env.VUE_APP_API + "items/list?limit=700&category=dota2")
      .then((res) => {
        this.productList = res.data.payload;
      })
      .catch(() => {
      });
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        this.categoryOptions = res.data.payload.types;
        this.qualitiyOptions = res.data.payload.qualities;
        this.getProducts();
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn();
        }
      })
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
  },
};
</script>
